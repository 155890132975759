
<div class="form-token-container">

    <span *ngFor="let holder of placeholders; index as i">
        <mat-form-field appearance="fill" [formGroup]="form">

            <input #input [formControlName]="i" maxLength="1"
                (input)="setFormValue($event, i)"
                (paste)="onPaste($event, i)" (keydown)="onKeydown($event, i)"
                [attr.type]="type" [attr.name]="i"
                [attr.data-testid]="'inputtoken-'+i"

                id="inputtoken-{{i}}" matInput />

        </mat-form-field>

    </span>
</div>


<div class="errors-container">
    <mat-error *ngIf="form.get('0')?.hasError('attemptsToken')">{{getErrorMessage()}}</mat-error>
</div>
