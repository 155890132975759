<ng-template cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="disableClose"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlayPanelClass="float-menu-component"
  [cdkConnectedOverlayOpen]="isOpen">
  <div (mouseover)="extendsMenu= true" (mouseleave)="closeMenu()"
    [ngClass]="{'menubox-fiserv-adm':
    extendsMenu, 'teerts': !extendsMenu}">

    <mat-icon [ngClass]="{'active': activeHomeMenu}" svgIcon="home">
    </mat-icon>
    <span [ngClass]="{'active': activeHomeMenu}" (click)="goHome()" *ngIf="extendsMenu">{{"HOME" | translate}}</span>
    <ng-template [ngIf]="isFD">

      <mat-icon [ngClass]="{'active': activeUsersInternalMenu}"  svgIcon="users">
      </mat-icon>
      <span [ngClass]="{'active': activeUsersInternalMenu}"  *ngIf="extendsMenu" (click)="openInternalUsersList()">{{"USER_INTERNAL"
        | translate}}</span>
    </ng-template>
    <ng-template [ngIf]="isFD">
      <mat-icon  [ngClass]="{'active': activeReportMenu}" svgIcon="report">
      </mat-icon>
      <span [ngClass]="{'active': activeReportMenu}" *ngIf="extendsMenu" (click)="openReport()">{{"REPORTS" |
        translate}}</span>
    </ng-template>
    <ng-template [ngIf]="isSuperAdmin">
      <mat-icon [ngClass]="{'active': activeSettings}" svgIcon="settings">
      </mat-icon>
      <span [ngClass]="{'active': activeSettings}" *ngIf="extendsMenu" (click)="openInstitutionChannelList()">{{"SETTINGS" |
        translate}}</span>
    </ng-template>
  </div>

</ng-template>
<div cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  class="menu"
  style="position: fixed; left: 0px;"></div>
<div class="main-toolbar-m" [ngClass]="{'active':
  mobileMenuActive}">

  <mat-icon *ngIf="!showArrowBackButton; else ArrowBackButtonContainer"
    (click)="showMobileMenu()" class="menu-hamb"
    svgIcon="menu-hamb"></mat-icon>

  <ng-template #ArrowBackButtonContainer>
    <span (click)="backToList()" class="material-icons">
      arrow_back
    </span>
  </ng-template>
  <div class="img-partner">
    <img mat-card-image [src]="partner?.logo" alt="logo">
    <mat-icon (click)="mobileMenuActive= false" svgIcon="close"></mat-icon>
  </div>

  <div class="options">
    <mat-icon [ngClass]="{'active': activeHomeMenu}"  svgIcon="home">
    </mat-icon>
    <span [ngClass]="{'active': activeHomeMenu}"  (click)="goHome()">{{"HOME" | translate}}</span>

    <ng-template [ngIf]="isFD">

      <mat-icon  [ngClass]="{'active': activeReportMenu}" svgIcon="report">
      </mat-icon>
      <span [ngClass]="{'active': activeReportMenu}" (click)="openReport()">{{"REPORTS" | translate}}</span>
      <mat-icon [ngClass]="{'active': activeUsersInternalMenu}" svgIcon="users">
      </mat-icon>
      <span [ngClass]="{'active': activeUsersInternalMenu}" (click)="openInternalUsersList()">{{"USER_INTERNAL"
        | translate}}</span>
    </ng-template>

    <ng-template [ngIf]="isSuperAdmin">
      <mat-icon [ngClass]="{'active': activeSettings}" svgIcon="settings">
      </mat-icon>
      <span [ngClass]="{'active': activeSettings}" (click)="openInstitutionChannelList()">{{"SETTINGS" |
        translate}}</span>
    </ng-template>

    <mat-icon svgIcon="logout">
    </mat-icon>
    <span (click)="logout()">{{'SIGN_OUT' | translate}}</span>
  </div>

</div>
