import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import {ScrollingModule} from '@angular/cdk/scrolling';

import { MatRadioModule } from '@angular/material/radio';

import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppFormComponent } from './app-form/app-form.component';
import { FormChangeConfirmEmailComponent } from './form-change-confirm-email/form-change-confirm-email.component';
import { FormChangeDoneComponent } from './form-change-done/form-change-done.component';
import { FormChangeNameComponent } from './form-change-name/form-change-name.component';
import { FormChangePasswordComponent } from './form-change-password/form-change-password.component';
import { FormControlComponent } from './form-control/form-control.component';
import { FormPasswordComponent } from './form-password/form-password.component';
import { FormTokenGroupComponent } from './form-token-group/form-token-group.component';
import { FormTokenGroupExpiredPasswordComponent } from './form-token-group-expired-password/form-token-group-expired-password.component';

import { FormTokenComponent } from './form-token/form-token.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { RouterModule } from '@angular/router';
import { GlobalizationModule } from '../globalization/globalization.module';

import { NgxMaskModule } from 'ngx-mask';
import { IsLoadingDirective } from './directives/is-loading.directive';
import { TableComponent } from './table/table.component';
import { FloatMenuComponent } from './float-menu/float-menu.component';
import { OptinComponent } from './optin/optin.component';
import { ModalRenewalComponent } from './modal-renewal/modal-renewal.component';
import { UserNameAuthenticateComponent } from './user-name-authenticate/user-name-authenticate.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { PageErrorNotFoundComponent } from './page-error-not-found/page-error-not-found.component';
import { ModalConfirmationInvitationComponent } from './modal-confirmation-invitation/modal-confirmation-invitation.component';
import { PageErrorUnavailableComponent } from './page-error-unavailable/page-error-unavailable.component';
import { ModalWarningRenewalComponent } from './modal-warning-renewal/modal-warning-renewal.component';
import { ModalConfirmationReportComponent } from './modal-confirmation-report/modal-confirmation-report.component';
import { UsersPendingInviteTableComponent } from './institutional-users-table-pending-invite/users-pending-invite-table.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CardExpiredPasswordComponent } from './card-expired-password/card-expired-password.component';
import { CardInactiveRegistrationComponent } from './card-inactive-registration/card-inactive-registration.component';
import { PageErrorNotAuthorizedComponent } from './page-error-not-authorized/page-error-not-authorized.component';
import { ModalInformationComponent } from './modal-information/modal-information.component';
import { SpecialCharacterDirective } from '../directive/SpecialCharacterDirective.directive';
import { OnlyNumbersDirective } from '../directive/OnlyNumbersDirective.directive';
import { CardChannelComponent } from './card-channel/card-channel.component';
import { ModalChannelApplicationComponent } from './modal-channel-application/modal-channel-application.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ModalAddServiceContractComponent } from './modal-add-service-contract/modal-add-service-contract.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {CdkMenuModule} from '@angular/cdk/menu';
import { ModalConfirmationServiceContractComponent } from
  './modal-confirmation-service-contract/modal-confirmation-service-contract.component';
import { CardUploadImageComponent } from './card-upload-image/card-upload-image.component';
import { SnackBarService } from './services/snackbar.service';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ModalWarningComponent } from './modal-warning/modal-warning.component';
import { PhonePipe } from './pipes/phone.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableListInvitedAllianceComponent } from './table-list-invited-alliance/table-list-invited-alliance.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { TableUsersComponent } from './table-users/table-users.component';
import { TableUserLogsComponent } from './table-user-logs/table-user-logs.component';
import { TableUserLogsDescriptionComponent } from './table-user-logs-description/table-user-logs-description.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DatepickerLanguageService } from '../api/services/datepickerLanguage.service';
import { ModalHandlePropertyComponent } from './modal-handle-property/modal-handle-property.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ModalGraphicInterfaceComponent } from './modal-graphic-interface/modal-graphic-interface.component';
import { LoginPrototypeComponent } from './login-prototype/login-prototype.component';
import { ModalHandleGroupComponent } from './modal-handle-property copy/modal-handle-group.component';
import { TableListInvitedGrafanaComponent } from './table-list-invited-grafana/table-list-invited-grafana.component';
import { FormChangePasswordLoggedComponent } from './change-password-logged/change-password-logged.component';
import { ChannelApiService } from '../api/services/channel-api.service';


@NgModule({
  declarations: [
    SpecialCharacterDirective,
    OnlyNumbersDirective,
    FormControlComponent,
    LoaderComponent,
    IsLoadingDirective,
    FormPasswordComponent,
    FormChangePasswordComponent,
    ModalConfirmationComponent,
    FormTokenComponent,
    FormChangeNameComponent,
    ModalHandleGroupComponent,
    TableListInvitedGrafanaComponent,
    FormChangeDoneComponent,
    FormTokenGroupComponent,
    FormTokenGroupExpiredPasswordComponent,
    FormChangeConfirmEmailComponent,
    AppFormComponent,
    TableComponent,
    TableListInvitedAllianceComponent,
    TableUsersComponent,
    FloatMenuComponent,
    PhonePipe,
    OptinComponent,
    ModalRenewalComponent,
    UserNameAuthenticateComponent,
    PageErrorComponent,
    PageErrorNotFoundComponent,
    ModalConfirmationInvitationComponent,
    ModalHandlePropertyComponent,
    PageErrorUnavailableComponent,
    ModalConfirmationReportComponent,
    ModalWarningRenewalComponent,
    UsersPendingInviteTableComponent,
    DateFormatPipe,
    CardExpiredPasswordComponent,
    CardInactiveRegistrationComponent,
    PageErrorNotAuthorizedComponent,
    ModalInformationComponent,
    CardChannelComponent,
    ModalChannelApplicationComponent,
    ModalAddServiceContractComponent,
    ModalConfirmationServiceContractComponent,
    CardUploadImageComponent,
    FileUploadComponent,
    ModalWarningComponent,
    TableUserLogsComponent,
    FormChangePasswordLoggedComponent,
    TableUserLogsDescriptionComponent,
    PaginationComponent,
    ModalGraphicInterfaceComponent,
    LoginPrototypeComponent
  ],
  providers: [
    SnackBarService,
    DatepickerLanguageService,
    ChannelApiService,
    {
      provide: MAT_DATE_LOCALE,
      useFactory: dateLocaleProviderFactory,
      deps: [DatepickerLanguageService]
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'overlayInputMidFormControl' }
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatSnackBarModule,
    MatListModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CdkMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    ScrollingModule,
    RouterModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    GlobalizationModule,
    OverlayModule,
    MatDialogModule,
    MatChipsModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    MatButtonModule,
    MatTooltipModule,
    CdkMenuModule,
    MatDatepickerModule,
    PaginationComponent,
    MatNativeDateModule,
    MatIconModule,
    FormControlComponent,
    LoaderComponent,
    MatTabsModule,
    IsLoadingDirective,
    FormChangePasswordComponent,
    FormPasswordComponent,
    ModalConfirmationComponent,
    ModalHandleGroupComponent,
    FormTokenComponent,
    FormChangeNameComponent,
    FormChangeDoneComponent,
    FormTokenGroupComponent,
    ScrollingModule,
    ModalWarningRenewalComponent,
    FormTokenGroupExpiredPasswordComponent,
    FormChangeConfirmEmailComponent,
    ModalRenewalComponent,
    AppFormComponent,
    TableComponent,
    TableListInvitedAllianceComponent,
    TableUsersComponent,
    FloatMenuComponent,
    PageErrorNotFoundComponent,
    MatMenuModule,
    PageErrorNotAuthorizedComponent,
    OptinComponent,
    UserNameAuthenticateComponent,
    UsersPendingInviteTableComponent,
    PhonePipe,
    DateFormatPipe,
    TableListInvitedGrafanaComponent,
    CardExpiredPasswordComponent,
    CardInactiveRegistrationComponent,
    ModalInformationComponent,
    CardChannelComponent,
    ModalWarningComponent,
    ModalAddServiceContractComponent,
    CardUploadImageComponent,
    FileUploadComponent,
    TableUserLogsComponent,
    FormChangePasswordLoggedComponent,
    TableUserLogsDescriptionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }

export function dateLocaleProviderFactory(datepickerLanguageService: DatepickerLanguageService) {
  return datepickerLanguageService.provideDateLocale();
}
