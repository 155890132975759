import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { Language } from '../models/language';
import { Languages } from '../models/languages';

import { StorageService } from 'src/app/storage/services/storage.service';
import { TranslateParams } from '../models/translate-params';
import { DatepickerLanguageService, availableLanguages } from 'src/app/api/services/datepickerLanguage.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalizationService {

  static readonly DEFAULT_LANGUAGE = Languages.ENGLISH_US;
  private temporaryLanguage: string | null = null;
  private readonly STORAGE_KEY = 'current_language';

  constructor(
    private _storageService: StorageService,
    private _translateService: TranslateService,
    private _datepickerLanguageService: DatepickerLanguageService

  ) {
    // const currentLanguage = this.currentLanguage;

    // this.setCurrentLanguage(currentLanguage.value);
  }

  get availableLanguages(): Array<Language> { return [Languages.ENGLISH_US, Languages.PORTUGUESE_BR, Languages.SPANISH_ES]; }

  public get currentLanguage(): Language {

    const currentLanguage = this._storageService.get(this.STORAGE_KEY) || this.temporaryLanguage;
    return this.languageByValue(currentLanguage);
  }

  public get languageSavedInLocalStorage():string | null {
    return this._storageService.get(this.STORAGE_KEY)
  }

  setTemporaryLanguage(languageValue: string) {
    const language = this.languageByValue(languageValue);
    this.setDatePickerLanguage(language.value as availableLanguages);
    this.temporaryLanguage = languageValue;
    return this._translateService.use(language.value);
  }

  setCurrentLanguage(languageValue: string): Observable<any> {
    this._storageService.save(this.STORAGE_KEY, languageValue);
    const language = this.languageByValue(languageValue);
    this.setDatePickerLanguage(language.value as availableLanguages);
    return this._translateService.use(language.value);
  }
  setCurrentLanguageWithPrefix(languageValue: string , prefix: string): Observable<any> {
    this._storageService.saveUsingPrefix(this.STORAGE_KEY, languageValue, prefix);
    const language = this.languageByValue(languageValue);
    this.setDatePickerLanguage(language.value as availableLanguages);
    return this._translateService.use(language.value);
  }
  setDatePickerLanguage(lang: availableLanguages) {
    this._datepickerLanguageService.setLanguage(lang)
  }

  translate(key: string, params?: Record<string, unknown> | TranslateParams | undefined) {
    return this._translateService.instant(key, params);
  }
  get(key: string, params?: Record<string, unknown> | TranslateParams | undefined): Observable<any> {
    return this._translateService.get(key, params);
  }

  private languageByValue(value: string | null): Language {

    if (!value && !!this.availableLanguages.length) {
      const has_default_browser_language = !!this.availableLanguages.filter(l => l.value === navigator.language)[0];

      if (has_default_browser_language) {

        return this.availableLanguages.filter(l => l.value === navigator.language)[0];
      }

    }

    const languages = this.availableLanguages.filter(l => l.value === value);


    if (languages === null || languages.length === 0)
      return GlobalizationService.DEFAULT_LANGUAGE;


    return languages[0];
  }
}
