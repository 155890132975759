import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { ChangePasswordBody, FormChangePasswordLoggedComponent, PasswordInterface } from '../change-password-logged/change-password-logged.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, finalize, Observable, Subscription, take } from 'rxjs';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { SnackBarService } from '../services/snackbar.service';
import { Language } from 'src/app/globalization/models/language';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { CookieService } from 'src/app/api/services/cookie.service';

@Component({
  selector: 'app-user-name-authenticate',
  templateUrl: './user-name-authenticate.component.html',
  styleUrls: ['./user-name-authenticate.component.scss']
})
export class UserNameAuthenticateComponent implements OnInit {

  socialName?: string;
  channel$?: Observable<string>;
  partnerName?: string;
  partner: Partner | undefined;

  userCanChangePassword:boolean = false;

  readonly languages: Language[];
  currentLanguage: Language;

  disableChannelClick:boolean = false;

  // private _subscription: Subscription = new Subscription();

  constructor(
    private _authApiService: AuthApiService,
    private _activatedRoute: ActivatedRoute,
    private _channelApiService: ChannelApiService,
    private _appPartner: AppPartner,
    private authenticationService: AuthApiService,
    private globalizationService: GlobalizationService,
    private _ngZone: NgZone,
    private _router: Router,
    private _cookieService: CookieService,
    private _storageServe: StorageService,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog
  ) { 
    this.languages = globalizationService.availableLanguages;
    this.currentLanguage = globalizationService.currentLanguage;
  }
  ngOnInit() {
    this.socialName = this._authApiService.getSocialName() || this._authApiService.getName();
    const routeParams = this._activatedRoute.snapshot.paramMap;
    this.partnerName = routeParams.get('partner') || 'fiserv';
    this.channel$ = this._channelApiService.channel$;

    this.disableChannelClick = this._activatedRoute.snapshot.data.disableChannelClick ? true : false
 
    // Disabled change password
    //this.userCanChangePassword = this._userCanChangePassword();

    this._appPartner
      .getPartner()
      .subscribe({
      next: (el) => {
        this.partner = el;
      },
    })

  }

  // ngOnDestroy() {
  //   this._subscription.unsubscribe();
  // }

  logout() {
    const partnerName = this._appPartner.getPartnerValue()?.routerPartnerName?.toLocaleLowerCase() || 'fiserv';
    this.authenticationService.logout().pipe(take(1), finalize(() => {
      this._ngZone.run(() => this._router.navigate([`${partnerName}/login`]));
    })).subscribe();
    this._storageServe.remove('userDetails');
    this._storageServe.remove('userChannel');
    this._cookieService.deleteFromDifferentPath('currentToken');
  }
  private _userCanChangePassword():boolean {
    // only Alliance user can change password
    return this._authApiService.isALLIANCE ;
  }

  changeLanguage(value: string): void {
    this.globalizationService.setCurrentLanguage(value);
    this.currentLanguage = this.globalizationService.currentLanguage;
  }

  changePassword() {
    
    const firstName = this._authApiService.getName().split(" ")[0];
    const lastName = this._authApiService.getName().split(" ")[1];
    const email = this._authApiService.getEmail();

    const dialog = this.dialog.open(FormChangePasswordLoggedComponent, {
      panelClass: 'change-password-logged-dialog-container',
      autoFocus: true,
      disableClose: true,
      data: { firstName, lastName, email},
    });
    dialog.afterClosed().subscribe({
      next: (response?: string) => {
        if(response) {
          this._snackBarService.showError(response);
        }
      },
    })
  }
}
