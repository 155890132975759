<app-float-menu></app-float-menu>
<div class="page">


      <app-user-name-authenticate></app-user-name-authenticate>


  <main class="content">

    <div class="title">


      <h1>{{"MANAGEMENT_ACCESS" | translate}}
      </h1>
      <p class="subtitle">{{"CHANGE_LIST_BIN" | translate:
        {channel:partnerName | titlecase} }}
      </p>


    </div>

    <div class="channel-info-box">
      <span class="table-title">{{"CHANNEL_LIST" |
        translate}}
      </span>
      <br>
      <span class="table-title">&nbsp;{{partnerName}}</span>
    </div>

      <div class="card-container">
        <ng-template ngFor let-item [ngForOf]="channels" let-i="index">
          <app-card-channel
          [tabindex]="i+1"
          [withBoxShadow]="false"
          [selectable]="true"		
          [showButton]="false"
          (selectableAction)="chosenChannel($event)"
          buttonText="{{'EDIT' | translate}}"
          [withBorder]="false"
          [connectedChannel]="true"
          [channel]="item"></app-card-channel>
          </ng-template>
      </div>

  </main>
</div>
