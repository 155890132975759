import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export declare type FormControlType = 'text';
interface InputEvent extends Event {
  data?: string;
}

@Component({
  selector: 'app-form-token',
  templateUrl: './form-token.component.html',
  styleUrls: ['./form-token.component.scss']
})
export class FormTokenComponent implements OnInit, AfterViewInit {

  @Input() label = '';
  @Input() name = '';
  @Input() type: FormControlType = 'text';
  @Input() id: string = Math.random() + '';
  @Input() next_id = '';

  @Input() amount!: number;
  @Output() childSubmit = new EventEmitter<any>();

  form: UntypedFormGroup = new UntypedFormGroup({});

  placeholders!: number[];

  constructor(private _controlContainer: ControlContainer) { }

  get control(): UntypedFormControl { return this.form.get(this.name) as UntypedFormControl; }

  ngOnInit(): void {
    this.placeholders = Array(this.amount).fill(1);
    this.form = this._controlContainer.control as UntypedFormGroup;

  }

  ngAfterViewInit() {
    setTimeout(() => document.getElementById(`inputtoken-0`)?.focus());
  }



  getErrorMessage(): string | undefined {

    if (!this.form.controls[0].errors)
      return;

    if (this.form.controls[0].errors?.attemptsToken)
      return this.form.controls[0].errors.attemptsToken;


    return Object.getOwnPropertyNames(this.form.controls[0].errors)[0].toUpperCase();
  }

  setFormValue({ data }: InputEvent | { data: string }, key: number): void {

    if (key > this.placeholders.length || !this.canSetValue(data)) {

      return;
    }

    this.form.patchValue({ [key]: data?.replace(/\D/g, '') || this.form.value['0']?.replace(/\D/g, '') });

    this.form.controls[key + 1]?.enable();

    document.getElementById(`inputtoken-${key + 1}`)?.focus();

    if (this.form.valid) this.childSubmit.emit();


  }

  onPaste(event: ClipboardEvent, key: number): void {

    const eventWithOnlyNumbers = event.clipboardData?.getData('text')?.replace(/\D/g, '');

    if (!eventWithOnlyNumbers) {

      event.preventDefault();
    }

    eventWithOnlyNumbers?.split('').forEach((value, index) => {

      if (this.hasNumber(value)) {

        if (!this.form.valid) this.setFormValue({ data: value?.replace(/\D/g, '') }, key + index);
      } else {
        event.preventDefault();
      }

    });

  }

  onKeydown($event: KeyboardEvent, key: number) {

    if ($event.key === 'Backspace' && this.form.controls[key].value) {
      $event.preventDefault();
      this.form.patchValue({ [key]: $event.key?.replace(/\D/g, '') });
      return;
    }

    if ($event.key === 'Backspace' && !this.form.controls[key].value) {
      $event.preventDefault();
      const newFocus = key - 1;
      this.form.patchValue({ [newFocus]: undefined });
      document.getElementById(`inputtoken-${newFocus}`)?.focus();
      if (key !== 0) {
        this.form.controls[key].disable();

      }
    } else if ((!this.canSetValue($event.key) && ($event.code !== 'KeyV'))) {
      $event.preventDefault();
      return;
    } else if (this.canSetValue($event.key)) {
      $event.preventDefault();
      this.setFormValue({ data: $event.key }, key);
    }

  }


  private canSetValue(value: any): boolean {
    if (this.isEmpty(value)) {
      return false;
    }

    const isDigitsValue = /^[0-9]+$/.test(value.toString());

    return isDigitsValue;
  }

  private isEmpty(value: any): boolean {
    return value === null || value === undefined || !value.toString().length;
  }

  private hasNumber(value: any): boolean {
    return !!value?.replace(/\D/g, '');
  }

}
