import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { GlobalizationService } from '../../globalization/services/globalization.service';

import { ToastrService } from 'ngx-toastr';
import { ApiObservable } from '../models/api-observable';

import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { Authenticate } from 'src/app/api/models/authentication-resp';
import { CookieService } from 'src/app/api/services/cookie.service';
import { InviteApiService } from 'src/app/api/services/invite.service';
import loadingJson from '@assets/lottie/dotsloading.json';
import { RenewalApiService } from 'src/app/api/services/renewal.service';
import { HttpResponse } from '@angular/common/http';

export declare type FormControlType = 'text';

@Component({
  selector: 'app-form-token-group',
  templateUrl: './form-token-group.component.html',
  styleUrls: ['./form-token-group.component.scss']
})
export class FormTokenGroupComponent implements OnInit, OnDestroy {

  @Input() auth_data!: Authenticate;
  @Input() title: string = this.globalizationService.translate('FIRST_ACCESS_TOKEN_TITTLE');
  @Input() weSentResent: string = this.globalizationService.translate('WE_SENT');
  @Input() subTitle: string = this.globalizationService.translate('FIRST_ACCESS_TOKEN_SUB_TITTLE');
  @Output() tokenSuccess: EventEmitter<any> = new EventEmitter();
  @Input() recovery?: number;
  @Input() client_id?: string;


  amount = 6;
  loadingPathJson = loadingJson;

  invalidToken = false;
  exceededAttempts = false;
  blockedUser = false;
  token_expired = false;
  attempts = true;
  userEmail?: string | null;
  blockedUserTime = '';

  timeToExpires?: string;

  form = this._fb.group({
    0: [undefined, [Validators.required, Validators.minLength(1)]],
    1: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    2: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    3: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    4: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    5: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],

  });

  obs$: ApiObservable<HttpResponse<{
    accessToken: string; refreshToken: string; scope: string;
    idToken: string; tokenType: string; expiresIn: string;
    redirectUri?: string; authorizationCode?: string;
  }>> | null = null;

  private subscription!: Subscription;

  constructor(private _fb: UntypedFormBuilder,
    private authService: AuthApiService,
    private toastr: ToastrService,
    private renewalApiService: RenewalApiService,
    private globalizationService: GlobalizationService,
    private cookieService: CookieService,
    private router: Router,
    private _inviteService: InviteApiService) {

  }

  ngOnInit(): void {

    this.userEmail = this.auth_data.email;
    const { expiresMilliseconds } = this.auth_data;
    this.startTimeCountToEndUsingMilliseconds(expiresMilliseconds);
  }

  startTimeCountToEndUsingMilliseconds(totalTimeInMilliseconds: number) {

    const initialDate = new Date();

    const initialCount = totalTimeInMilliseconds;
    this.subscription = interval(1000)
      .subscribe((milliseconds) => {

        let timePassed;
        const currentDate = new Date();

        if((currentDate.getTime() > initialDate.getTime()) && (initialDate.getDate() === currentDate.getDate())) {
          timePassed = Date.now() - initialDate.getTime();
        } else {
          timePassed = milliseconds * 1000;
        }
        const timeToEnd = initialCount - timePassed;
        const minutes = Math.floor(timeToEnd / 60000);
        const seconds = Math.round((timeToEnd % 60000) / 1000);

        if (timeToEnd <= 0) {
          this.tokenExpired();
        }
        this.timeToExpires = seconds === 60
          ? `${minutes + 1}:00`
          : `${minutes}:${this.padTo2Digits(seconds)}`;
      });
  }

  tokenExpired() {
    this.toastr.clear();
    this.token_expired = true;
    this.form.disable();
    this.subscription.unsubscribe();
    this.toastr.error(this.globalizationService.translate('CODE_EXPIRED'), '',
      { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
  }


  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  submitToken(): void {

    this.disableDefaultForm();

    let token = Object.values(this.form.value).toString();
    token = token.replace(/\s|,/g, '');

    if (this.recovery === 1) {
      this._inviteService.validationMfaUser({ mfaId: this.auth_data.mfaId, tokenValue: token }).pipe(take(1)).subscribe((data) => {
        this.subscription.unsubscribe();
        this.tokenSuccess.emit(data);
      }, (err) => {

        let msg = '';
        let code = 0;
        if (err.error?.errors) {
          msg = err.error?.errors[0]?.description;
          code = err.error?.errors[0]?.code;
        }

        this.tokenInvalid({ msg, code });

      });
    } else if (this.recovery === 2) {
      this.renewalApiService.renewalValidTokenPassword({ mfaId: this.auth_data.mfaId, tokenValue: token })
        .pipe(take(1)).subscribe((data) => {
          this.subscription.unsubscribe();
          this.tokenSuccess.emit(data);
        }, (err) => {

          let msg = '';
          let code = 0;
          if (err.error?.errors) {
            msg = err.error?.errors[0]?.description;
            code = err.error?.errors[0]?.code;
          }

          this.tokenInvalid({ msg, code });

        });
    }
    else {
      this.obs$ = this.authService.validToken(`${this.auth_data.mfaId}`, token, this.client_id);
      this.obs$.pipe(take(1)).subscribe((data) => {

        this.subscription.unsubscribe();
        this.tokenSuccess.emit(data.body);

      }, (err) => {
        let msg = '';
        let code = 0;
        if (err.error?.errors) {
          msg = err.error?.errors[0]?.description;
          code = err.error?.errors[0]?.code;
        }
        this.tokenInvalid({ msg, code });
        const shouldCreateToast = [603, 604, 605].some(element => element === code);
        if (shouldCreateToast) {
          this.toastr.error(msg, '',
            { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
        } else {
          throw err;
        }
      });
    }

  }


  unblock() {


    if (this.exceededAttempts) {
      return;
    }

    this.form.controls[5].disable();
    this.form.controls[4].disable();
    this.form.controls[3].disable();
    this.form.controls[2].disable();
    this.form.controls[1].disable();
    this.form.controls[0].enable();
    document.getElementById(`inputtoken-0`)?.removeAttribute('disabled');
    document.getElementById(`inputtoken-0`)?.focus();
    this.form.reset();
    this.invalidToken = false;
  }

  reSendToken(): void {
    this.authService.resendToken(this.auth_data.mfaId).pipe(take(1)).subscribe((data) => {

      this.weSentResent = this.globalizationService.translate('WE_RESENT');
      this.subscription.unsubscribe();

      if (data?.blockedTimeMilliseconds > 1000) {
        this.startTimeCountToEndUsingMilliseconds(data.blockedTimeMilliseconds);
        this.userblocked();
        return;
      }

      this.blockedUser = false;
      this.exceededAttempts = false;
      this.invalidToken = false;
      this.token_expired = false;
      this.toastr.clear();
      this.toastr.success(this.globalizationService.translate('CODE_SENT'), '',
        { positionClass: 'toast-top-center', toastClass: 'ngx-toastr form-token-group-toast resend' });


      this.form.reset();
      this.unblock();
      this.startTimeCountToEndUsingMilliseconds(data.expiresMilliseconds);
    });
  }

  private userblocked(): void {
    this.toastr.clear();
    this.disableDefaultForm();
    this.blockedUser = true;

    this.toastr.error(this.globalizationService.translate('REGISTRATION_BLOCKED'), '',
      { positionClass: 'toast-top-center', toastClass: 'ngx-toastr form-token-group-toast-error-user-blocked', disableTimeOut: true });

  }

  private disableDefaultForm(withReset?: boolean) {

    for (let i = 0; i < this.amount; i++) {

      if (withReset) {
        this.form.controls[i].reset();
      }

      this.form.controls[i].disable();
      document.getElementById(`inputtoken-${i}`)?.setAttribute(`disabled`, '');
    }

  }

  private tokenInvalid({ msg, code }: { msg: string; code: number }): void {

    // Usuário errou o codigo mas ainda tem tentativas - OK
    if (code === 602) {
      this.invalidToken = true;
      this.form.controls[0].setErrors({ attemptsToken: msg });
      return;
    }

    // Token expirado
    if (code === 603) {
      this.token_expired = true;
      this.disableDefaultForm();
      return;
    }

    // Usuário excedeu as tentativas
    if (code === 604) {
      this.disableDefaultForm();
      this.exceededAttempts = true;
    }
    // Usuário foi bloqueado por 30 minutos
    if (code === 605) {

      this.disableDefaultForm();
      this.exceededAttempts = true;
      return;
    }

    if (code === 500) {
      this.disableDefaultForm();
      return;
    } else {
      this.invalidToken = true;
      this.disableDefaultForm(true);
    }

  }

}
