import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { PartnerEnum } from 'src/partner/partner.enum';
import { AppPartner } from 'src/partner/partner.service';
import { HelpersModule } from './helpers.module';
import { GlobalizationService } from '../globalization/services/globalization.service';
import { StorageService } from '../storage/services/storage.service';

@Injectable({ providedIn: HelpersModule })

export class InternalUserResolver implements Resolve<boolean> {

  constructor(
    private _appPartner: AppPartner,
    private _globalizationService: GlobalizationService,
    private _storageService: StorageService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    this.setUserLanguage('fiserv')
    return this._appPartner.setPartnerByName(PartnerEnum.Fiserv);
  }

  setUserLanguage(partner: string, language: string = 'en-US') {
    const userHasSetLanguage = this._storageService.getUsingPrefix('current_language', `_${partner}_`)
    if(userHasSetLanguage) {
        this._globalizationService.setCurrentLanguage(userHasSetLanguage);
    } else if(language) {
        this._globalizationService.setTemporaryLanguage(language)
    } 
}
}
